import { createDomain, sample, combine, attach } from 'effector'
import { createGate } from 'effector-react'
import { debtModel, cashModalModel, formModel } from '~/entities/Debt'
import { AxiosErrorType, Debt } from '~/shared/api'
import { DebtStatusesEnum } from '~/shared/config/enums'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'
import { formButtonsModel } from '~/shared/ui/Form'

export const Gate = createGate<{ debtId: UniqueId }>()

export const domain = createDomain('features.debts.view')

export const $debtId = domain
  .createStore<UniqueId | null>(null)
  .on(Gate.state, (_, { debtId }) => debtId)

export const $debt = combine($debtId, debtModel.$debtsCache, (id, cache) => {
  if (!id) return null
  return cache.map[id] ?? null
})

sample({
  clock: $debtId,
  filter: isString,
  target: debtModel.requestFx,
})

// Update
export const formSubmitted = domain.createEvent<formModel.FormValues>()
export const debtUpdateFx = attach({
  effect: debtModel.updateFx,
  source: $debtId,
  mapParams: (
    { total, paymentCost, comment, carOption }: formModel.FormValues,
    debtId,
  ) => {
    return {
      debtId: debtId as UniqueId,
      values: {
        total,
        paymentCost,
        comment,
        carId: carOption as UniqueId,
      },
    }
  },
})

sample({
  clock: formSubmitted,
  target: debtUpdateFx,
})

sample({
  clock: debtUpdateFx.doneData,
  fn() {
    return {
      message: 'Сохранено успешно',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, formButtonsModel.editingEnded],
})

sample({
  clock: debtUpdateFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка при сохранении данных задолженности',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Status change
export const sendStatusChangeFx = domain.createEffect<
  {
    status: DebtStatusesEnum
    debtId: string
  },
  void
>({
  async handler({ status, debtId }) {
    await Debt.changeStatus(status, debtId)
  },
})
export const statusChangeFx = attach({
  effect: sendStatusChangeFx,
  source: $debtId,
  mapParams: (status: DebtStatusesEnum, debtId) => ({
    status,
    debtId: debtId as UniqueId,
  }),
})

sample({
  clock: statusChangeFx.doneData,
  fn() {
    return {
      message: 'Статус успешно изменён',
      variant: 'success' as const,
    }
  },
  target: snackbarEnqueued,
})
sample({
  clock: statusChangeFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: 'Ошибка смены статуса',
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

// Cash deposit
export const sendCashDepositFx = domain.createEffect<
  {
    formValues: cashModalModel.FormValues
    fn: () => Promise<void>
    debtId: string
  },
  void,
  AxiosErrorType
>({
  async handler({ formValues, debtId, fn }) {
    await Debt.cashDeposit(formValues, debtId)
    await fn()
  },
})

export const cashDeposit = domain.createEvent<{
  formValues: cashModalModel.FormValues
  fn: () => Promise<void>
}>()

export const cashDepositFx = attach({
  effect: sendCashDepositFx,
  source: $debtId,
  mapParams: (
    params: {
      formValues: cashModalModel.FormValues
      fn: () => Promise<void>
    },
    debtId,
  ) => ({
    ...params,
    debtId: debtId as UniqueId,
  }),
})

sample({
  clock: cashDeposit,
  target: cashDepositFx,
})

sample({
  clock: cashDepositFx.doneData,
  fn() {
    return {
      message: 'Успешное погашение задолженности',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, cashModalModel.closeCash],
})

sample({
  clock: cashDepositFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})

sample({
  clock: [statusChangeFx.doneData, cashDepositFx.doneData],
  source: $debtId,
  filter: isString,
  target: debtModel.requestSilentFx,
})
