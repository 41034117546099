import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributeSchema = z.object({
  fullName: z.string(),
  extId: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributeSchema)

export type DriverAttributes = z.infer<typeof attributeSchema>

export class Driver extends ApiModel<typeof schema> {
  static jsonApiType = 'drivers'

  static schema = schema

  getFullName(): string {
    return this.getAttribute('fullName')
  }

  getExtId(): UniqueId {
    return this.getAttribute('extId')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getFullName() as string,
    }
  }

  static async fetchOptions(search: string) {
    const response = await Driver.where('fullName', search ?? '').get(1)
    return response.getData().map((o) => o.getOption())
  }

  getOptionByExtId(): { id: UniqueId; label: string } {
    return {
      id: this.getExtId() as UniqueId,
      label: this.getFullName() as string,
    }
  }

  static async fetchOptionsByExtId(search: string) {
    const response = await Driver.where('fullName', search ?? '').get(1)
    return response.getData().map((o) => o.getOptionByExtId())
  }
}
