import { blue } from '@mui/material/colors'
import { ruRU as coreRuRU } from '@mui/material/locale'
import { createTheme } from '@mui/material/styles'
import { ruRU as gridRuRU } from '@mui/x-data-grid'
import type {} from '@mui/x-data-grid/themeAugmentation'

export const theme = createTheme(
  {
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiTextField: {
        defaultProps: {
          size: 'small',
        },
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            padding: '8px',
            '&.Mui-disabled.Mui-checked': {
              color: blue[700],
              opacity: '0.8',
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-cell': {
              alignItems: 'flex-start',
              padding: '10px',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none !important',
            },
            '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
            '& > :first-of-type': {
              background: '#ffffff',
              zIndex: 1,
              borderRadius: '3px',
            },
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.6)',
            },
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 1)',
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            '& .Mui-disabled .MuiAutocomplete-tag': {
              opacity: '1',
            },
            '& .Mui-disabled .MuiChip-deleteIcon': {
              opacity: '0.3',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '& input.Mui-disabled, .MuiSelect-select.Mui-disabled': {
              color: 'rgba(0, 0, 0, 1)',
              WebkitTextFillColor: 'rgba(0, 0, 0, 1)',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          root: {
            maxHeight: '100%',
            overflow: 'auto',
          },
        },
      },
    },
  },
  gridRuRU,
  coreRuRU,
)
