import { createDomain } from 'effector'
import { useStoreMap } from 'effector-react'
import { AxiosErrorType, Debt, UpdateDebtType } from '~/shared/api'
import { createCache } from '~/shared/lib/mapCacheFactory'

export const domain = createDomain('entities.debt')

export const requestFx = domain.createEffect<UniqueId, Debt>({
  handler: fetchDebt,
})

export const requestSilentFx = domain.createEffect<UniqueId, Debt>({
  handler: fetchDebt,
})

export const updateFx = domain.createEffect<
  {
    values: UpdateDebtType
    debtId: UniqueId
  },
  Debt
>({
  async handler({ values, debtId }) {
    await Debt.updateDebt(values, debtId)
    return fetchDebt(debtId as UniqueId)
  },
})

export const saveFx = domain.createEffect<Debt, Debt, AxiosErrorType>({
  async handler(debt) {
    await debt.save()
    return fetchDebt(debt.getApiId() as UniqueId)
  },
})

const {
  $cache: $debtsCache,
  useCache: useDebtCache,
  updateCache,
} = createCache<Debt>({
  domain,
  getEntityId: (debt) => debt.getApiId() as UniqueId,
})
export { $debtsCache, useDebtCache }

$debtsCache
  .on([requestFx.doneData, requestSilentFx.doneData], (cache, debt) =>
    updateCache(cache, [debt]),
  )
  .on([saveFx.doneData, updateFx.doneData], (cache, debt) =>
    updateCache(cache, [debt], true),
  )

export const $debtsError = domain
  .createStore<Record<UniqueId, Error>>({})
  .on(
    [requestFx.fail, requestSilentFx.fail],
    (store, { error, params: id }) => ({
      [id]: error,
      ...store,
    }),
  )

export const useDebtError = (id: UniqueId) =>
  useStoreMap($debtsError, (errors) => errors[id])

async function fetchDebt(id: UniqueId) {
  const response = await Debt.with('car').with('driver').find(id)
  return response.getData() as Debt
}
