import { useStore, useStoreMap } from 'effector-react'
import { Element } from 'react-scroll'
import {
  DetailDetailingList,
  CashList,
  ViolationDetailingList,
  Form,
  debtModel,
} from '~/entities/Debt'
import { usePermissions } from '~/entities/viewer'
import { debtTypeEnumLabels } from '~/shared/config/constants'
import { DebtStatusesEnum, DebtSourceTypesEnum } from '~/shared/config/enums'
import { Container } from '~/shared/ui/Layout'
import { $debt, formSubmitted, debtUpdateFx } from './model'

export const CASH_DEPOSIT_GRID_KEY = 'cash-deposit-list'

type ViewDebtProps = {
  debtId: UniqueId
}

export const ViewDebt = ({ debtId }: ViewDebtProps) => {
  const { canDebtsUpdate } = usePermissions()

  const debt = useStore($debt)
  const sourceType = debt?.getSourceType()
  const status = debt?.getStatus()

  const isLoading = useStore(debtModel.requestFx.pending)
  const isSubmitting = useStore(debtUpdateFx.pending)

  const canCarEdit = sourceType === DebtSourceTypesEnum.BALANCE_CORRECTION
  const isPaid = status === DebtStatusesEnum.PAID

  const defaultValues = useStoreMap($debt, (debt) => {
    const sourceType = debt?.getSourceType()
    const driver = debt?.getDriver()
    const car = debt?.getCar()
    return {
      ...debt?.getAttributes(),
      leftAmount: debt?.getBalance(),
      carExtId: debt?.getCar()?.getExtId(),
      driverExtId: debt?.getDriver()?.getExtId(),
      carOption: car && debt?.getCar()?.getOption(),
      driverOption: driver && debt?.getDriver()?.getOption(),
      sourceType: sourceType && {
        id: sourceType,
        label: debtTypeEnumLabels[sourceType],
      },
      sourceTitle: debt?.getSourceTitle(),
      sourceUrl: debt?.getSourceLink(),
    }
  })

  return (
    <>
      <Form
        defaultValues={defaultValues}
        canEdit={!isPaid && canDebtsUpdate}
        isSubmitting={isSubmitting}
        isLoading={isLoading}
        onSuccess={formSubmitted}
        canCarEdit={canCarEdit}
      />

      {sourceType === DebtSourceTypesEnum.INSPECTION_DAMAGES && (
        <Element name='detailing'>
          <Container>
            <DetailDetailingList debtId={debtId} />
          </Container>
        </Element>
      )}
      {sourceType === DebtSourceTypesEnum.INSPECTION_VIOLATIONS && (
        <Element name='detailing'>
          <Container>
            <ViolationDetailingList debtId={debtId} />
          </Container>
        </Element>
      )}
      <Element name='cashList'>
        <Container>
          <CashList debtId={debtId} gridKey={CASH_DEPOSIT_GRID_KEY} />
        </Container>
      </Element>
    </>
  )
}
