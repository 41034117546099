import { uniq } from 'lodash'
import { useMemo } from 'react'
import { usePermissions } from '~/entities/viewer'

export const usePermissionsPage = () => {
  const {
    canUsersView,
    canUsersUpdate,
    canRolesUpdate,
    canRolesView,
    canSettingsView,
    canDebtsView,
    canDebtsUpdate,
  } = usePermissions()

  const notPermissions = useMemo(() => {
    let data: string[] = []

    if (!canSettingsView) data = data.concat('/settings/*')

    if (!canUsersView)
      data = data.concat(['/settings/employees', '/settings/employees/*'])
    if (!canUsersUpdate) data = data.concat(['/settings/employees/new'])

    if (!canRolesView)
      data = data.concat('/settings/roles', '/settings/roles/*')
    if (!canRolesUpdate) data = data.concat(['/settings/roles/new'])

    if (!canDebtsView)
      data = data.concat(['/debts', '/debts/new', '/debts/:debtId'])
    if (!canDebtsUpdate) data = data.concat(['/debts/new'])

    return uniq<string>(data)
  }, [
    canSettingsView,
    canUsersView,
    canUsersUpdate,
    canRolesView,
    canRolesUpdate,
    canDebtsView,
    canDebtsUpdate,
  ])

  return { notPermissions }
}
