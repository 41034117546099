import { ToOneRelation } from 'coloquent'
import * as z from 'zod'
import { Debt } from '~/shared/api/debt'
import {
  dateSchema,
  priceRequiredScheme,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  cost: priceRequiredScheme,
  description: z.string(),
})
const relationsSchema = z.object({
  debtId: uuidOptionsSchema,
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type DebtDamageAttributes = z.infer<typeof schema>

export class DebtDamage extends ApiModel<typeof schema, DebtDamageAttributes> {
  static jsonApiType = 'debt-damages'

  static schema = schema

  debt(): ToOneRelation<Debt, this> {
    return this.hasOne(Debt)
  }
  getDebt(): Debt {
    return this.getRelation('debt')
  }
}
