import * as z from 'zod'
import { Debt } from '~/shared/api'
import { Option, OptionEnum } from '~/shared/config/constants'
import { DebtSourceTypesEnum } from '~/shared/config/enums'
import { enumOptionSchema } from '~/shared/lib/schemas'

export const formSchema = Debt.schema
  .pick({
    total: true,
    paymentCost: true,
    comment: true,
    carOption: true,
    driverOption: true,
  })
  .merge(
    z.object({
      sourceType: enumOptionSchema(DebtSourceTypesEnum),
    }),
  )

export type TypeFormSchema = z.infer<typeof formSchema>

export type FormValues = Omit<
  TypeFormSchema,
  'carOption' | 'driverOption' | 'sourceType'
> & {
  leftAmount: number | undefined
  paid: number | undefined
  carOption: Option | UniqueId
  driverOption: Option | UniqueId
  sourceType: OptionEnum<DebtSourceTypesEnum> | DebtSourceTypesEnum | null
  carExtId?: UniqueId
  driverExtId?: UniqueId
  sourceUrl?: string | null
  sourceTitle: string | null
}
