import { ToOneRelation } from 'coloquent'
import * as z from 'zod'
import { Debt } from '~/shared/api/debt'
import { User } from '~/shared/api/user'
import {
  dateSchema,
  priceRequiredScheme,
  uuidOptionsSchema,
} from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  amount: priceRequiredScheme,
  depositingDate: dateSchema,
  comment: z.string(),
})
const relationsSchema = z.object({
  debtId: uuidOptionsSchema,
  userId: uuidOptionsSchema,
})

const schema = z
  .object({
    createdAt: dateSchema,
    updatedAt: dateSchema,
  })
  .merge(attributesSchema)
  .merge(relationsSchema)

export type CashDepositAttributes = z.infer<typeof schema>

export class CashDeposit extends ApiModel<
  typeof schema,
  CashDepositAttributes
> {
  static jsonApiType = 'cash-deposits'

  static schema = schema

  debt(): ToOneRelation<Debt, this> {
    return this.hasOne(Debt)
  }
  getDebt(): Debt {
    return this.getRelation('debt')
  }
  user(): ToOneRelation<User, this> {
    return this.hasOne(User)
  }
  getUser(): User {
    return this.getRelation('user')
  }
}
