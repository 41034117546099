import {
  FormControl,
  FormLabel,
  Typography,
  Switch as JoySwitch,
  SwitchProps as JoySwitchProps,
  switchClasses,
  Skeleton,
  FormControlProps,
} from '@mui/joy'
import { Controller } from 'react-hook-form'
import { myPalette } from '~/shared/lib/theme'

type SwitchProps = {
  name: string
  label: string | number
  skeletonShow?: boolean
  formControlProps?: FormControlProps
} & Omit<JoySwitchProps, 'name'>

export function Switch({
  name,
  label,
  skeletonShow,
  formControlProps,
  ...props
}: SwitchProps) {
  return (
    <Controller
      name={name}
      defaultValue={false}
      render={({ field: { onChange, value } }) => (
        <FormControl
          orientation='horizontal'
          {...formControlProps}
          sx={{ alignItems: 'center', ...formControlProps?.sx }}
        >
          <JoySwitch
            checked={!!value}
            onChange={() => !props.readOnly && onChange(!value)}
            {...props}
            slotProps={{
              ...(skeletonShow
                ? {
                    root: {
                      component: () => (
                        <Skeleton
                          sx={{
                            width: '36px',
                            height: '20px',
                            borderRadius: '12px',
                            position: 'relative',
                          }}
                        />
                      ),
                    },
                  }
                : {}),
            }}
            sx={{
              '--Switch-trackWidth': '36px',
              '--Switch-trackBackground': myPalette.gray['300'],
              '&:hover': {
                '--Switch-trackBackground': myPalette.gray['300'],
              },
              [`& .${switchClasses.thumb}`]: {
                transition: 'width 0.2s, left 0.2s',
              },
              [`&.${switchClasses.checked}`]: {
                '--Switch-trackBackground': myPalette.brand['600'],
                '&:hover': {
                  '--Switch-trackBackground': myPalette.brand['600'],
                },
              },
              [`&.${switchClasses.readOnly}`]: {
                input: {
                  cursor: 'unset !important',
                },
                '--Switch-trackBackground': myPalette.gray['100'],
                '&:hover': {
                  '--Switch-trackBackground': myPalette.gray['100'],
                },
              },
            }}
          />
          <FormLabel sx={{ marginLeft: '8px' }}>
            <Typography fontSize='14px'>{label}</Typography>
          </FormLabel>
        </FormControl>
      )}
    />
  )
}
