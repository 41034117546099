import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useForm } from 'react-hook-form'
import { FormValues } from '~/entities/Debt/ui/CashModal/model'
import { cashDepositSchema } from '~/shared/api'
import { Button } from '~/shared/ui/Buttons'
import {
  DatePickerInput,
  FormProvider,
  NumericInput,
  TextareaInput,
} from '~/shared/ui/Form'
import { UploadWhiteIcon } from '~/shared/ui/Icons'

type FormProps = {
  onSuccess: (formValues: FormValues) => void
  isSubmitting: boolean
}

export function Form({ onSuccess, isSubmitting }: FormProps) {
  const form = useForm<FormValues>({
    resolver: zodResolver(cashDepositSchema),
  })

  return (
    <FormProvider form={form} onSuccess={onSuccess}>
      <Grid container spacing={2.5} sx={{ padding: '4px' }}>
        <Grid xs={6}>
          <NumericInput
            name='amount'
            label='Сумма'
            placeholder='Введите сумму'
            numericProps={{
              suffix: ' руб',
            }}
          />
        </Grid>
        <Grid xs={6}>
          <DatePickerInput
            placeholder='Выберите дату'
            label='Дата внесения'
            name='depositingDate'
          />
        </Grid>
        <Grid xs={12}>
          <TextareaInput
            label='Комментарий'
            name='comment'
            placeholder='Введите комметарий'
            minRows={8}
            maxRows={8}
          />
        </Grid>
      </Grid>
      <Button
        type='submit'
        loading={isSubmitting}
        fullWidth
        sx={{ marginTop: '40px' }}
        startDecorator={<UploadWhiteIcon />}
      >
        Внести сумму
      </Button>
    </FormProvider>
  )
}
