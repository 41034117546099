import { attach, createDomain, sample } from 'effector'
import { debtModel, formModel } from '~/entities/Debt'
import { Debt } from '~/shared/api'
import { backFx } from '~/shared/lib/history'
import { logger } from '~/shared/lib/logger'
import { mapMessageErrors } from '~/shared/lib/mapMessageErrors'
import { snackbarEnqueued } from '~/shared/lib/notifications'
import { isString } from '~/shared/lib/utils'

export const domain = createDomain('features.debt.add')

export const formSubmitted = domain.createEvent<formModel.FormValues>()
export const createFx = attach({
  effect: debtModel.saveFx,
  mapParams: ({ carOption, driverOption, ...values }) => {
    const debt = new Debt({ ...values, paid: 0, balance: values.total })
    if (isString(carOption)) debt.setCar(carOption)
    if (isString(driverOption)) debt.setDriver(driverOption)

    return debt
  },
})

sample({
  clock: formSubmitted,
  target: createFx,
})

sample({
  clock: createFx.doneData,
  fn() {
    return {
      message: 'Задолженность создана',
      variant: 'success' as const,
    }
  },
  target: [snackbarEnqueued, backFx],
})
sample({
  clock: createFx.failData,
  fn(e) {
    logger.error(e)
    return {
      message: mapMessageErrors(e),
      variant: 'error' as const,
    }
  },
  target: snackbarEnqueued,
})
