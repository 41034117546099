import { createDomain } from 'effector'
import { CashDepositSchemaType } from '~/shared/api'

export type FormValues = CashDepositSchemaType

const domain = createDomain('widgets.cashDeposit')

export const openCash = domain.createEvent()
export const closeCash = domain.createEvent()

export const $isCashOpen = domain
  .createStore<boolean>(false)
  .on(openCash, () => true)
  .on(closeCash, () => false)
