import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { MainLayout } from '~/widgets/layout/main'
import { MinimalLayout } from '~/widgets/layout/minimal'
import { ProtectedPage } from '~/features/Auth/protected-page'
import NotFoundPage from './404'
import { AddDebtPage } from './Debt/AddDebtPage'
import { ListDebtsPage } from './Debt/ListDebtsPage'
import { ViewDebtPage } from './Debt/ViewDebtPage'
import { usePermissionsPage } from './hooks/usePermissionsPage'
import { LoginPage } from './Login'
import { NoAccess } from './NoAccess'
import SettingsPage from './Settings'
import UserPersonalDataPage from './UserPersonalData'

export const Routing: React.FC = () => {
  const location = useLocation()
  const { notPermissions } = usePermissionsPage()

  const [notPermissionsPage, setNotPermissionsPage] = useState(notPermissions)

  // Найти лучший способ для ререндера роутов
  useEffect(() => {
    if (!isEqual(notPermissions, notPermissionsPage)) {
      setNotPermissionsPage(notPermissions)
    }
    // eslint-disable-next-line
  }, [location])

  return (
    <Routes>
      <Route element={<ProtectedPage />}>
        <Route element={<MainLayout />}>
          {notPermissionsPage?.map((path) => (
            <Route key={path} path={path} element={<NoAccess />} />
          ))}

          <Route path='/' element={<Navigate to='/debts' />} />

          <Route path='/debts' element={<ListDebtsPage />} />
          <Route path='/debts/new' element={<AddDebtPage />} />
          <Route path='/debts/:debtId' element={<ViewDebtPage />} />

          <Route path='/settings/*' element={<SettingsPage />} />

          <Route
            path='/user-personal-data'
            element={<UserPersonalDataPage />}
          />
        </Route>
      </Route>

      <Route element={<MinimalLayout />}>
        <Route path='/login' element={<LoginPage />} />
      </Route>

      <Route element={<MainLayout />}>
        <Route path='*' element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
