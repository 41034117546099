import { useCallback } from 'react'
import { DebtDamage } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { GridRow } from './types'

type DetailDetailingProps = {
  debtId: UniqueId
}

export const DetailDetailingList = (props: DetailDetailingProps) => {
  const { debtId } = props
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = DebtDamage.limit(pageSize)
        .where('debtId', debtId)
        .orderBy('-createdAt')

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((detail: DebtDamage) => ({
        ...detail.getAttributes(),
        id: detail?.getApiId() as UniqueId,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [debtId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Детализация'
      gridKey='detail-detailing-list'
      fetchRows={fetchRows}
      columns={columns}
      memoryLocal
    />
  )
}
