import { useCallback } from 'react'
import { usePermissions } from '~/entities/viewer'
import { Debt } from '~/shared/api'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { Button } from '~/shared/ui/Buttons'
import { PlusIcon } from '~/shared/ui/Icons'
import { columns } from './columns'
import { filters } from './filters'
import { GridRow } from './types'

export const ListDebts = () => {
  const { canDebtsUpdate } = usePermissions()

  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, filters = [], sort) => {
      let builder = Debt.limit(pageSize).with('car').with('driver')

      filters?.forEach(({ key, value }) => {
        if (key === 'driverExtId') {
          builder = builder.option('filter[driver][extId]', value)
          return
        }
        builder = builder.where(key, value)
      })

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((debt: Debt) => ({
        ...debt.getAttributes(),
        source: { url: debt.getSourceLink(), title: debt.getSourceTitle() },
        id: debt?.getApiId() as UniqueId,
        car: debt?.getCar()?.getPlateNumber(),
        carExtId: debt?.getCar()?.getExtId(),
        driver: debt?.getDriver()?.getFullName(),
        driverExtId: debt?.getDriver()?.getExtId(),
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [],
  )

  return (
    <AsyncGrid<GridRow>
      gridKey='debts-list'
      fetchRows={fetchRows}
      columns={columns}
      filters={filters}
      title='Задолженности'
      headerExtra={
        <Button startDecorator={<PlusIcon />} to='new' show={canDebtsUpdate}>
          Добавить
        </Button>
      }
    />
  )
}
