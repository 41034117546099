import { Form } from '~/entities/Debt/ui/CashModal/Form'
import { Modal } from '~/shared/ui/Modal'
import { FormValues } from './model'

type CashModalProps = {
  isShow: boolean
  onOk: (formValues: FormValues) => void
  onCancel: () => void
  isSubmitting: boolean
}

export function CashModal({
  isShow,
  onOk,
  onCancel,
  isSubmitting,
}: CashModalProps) {
  return (
    <Modal
      isShow={isShow}
      onCancel={isSubmitting ? undefined : onCancel}
      title='Внести средства'
      dialogProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '600px',
        },
      }}
    >
      <Form onSuccess={onOk} isSubmitting={isSubmitting} />
    </Modal>
  )
}
