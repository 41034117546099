import { useStore, useStoreMap } from 'effector-react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { debtViewModel } from '~/features/Debt/ViewDebt'
import { debtModel } from '~/entities/Debt'
import { usePermissions } from '~/entities/viewer'
import {
  debtStatusEnumLabels,
  debtStatusEnumOptions,
  STATUS_VARIANT,
} from '~/shared/config/constants'
import { DebtStatusesEnum } from '~/shared/config/enums'
import { AutocompleteInput, FormProvider } from '~/shared/ui/Form'
import { StatusFormValues } from './model'

export function Status() {
  const { canDebtsUpdate } = usePermissions()

  const isChanged = useStore(debtViewModel.statusChangeFx.pending)
  const isDebtLoading = useStore(debtModel.requestFx.pending)

  const defaultValues = useStoreMap(debtViewModel.$debt, (debt) => {
    const status = debt?.getStatus()
    return {
      status: status && {
        id: status,
        label: debtStatusEnumLabels[status as DebtStatusesEnum],
      },
    }
  })

  useEffect(() => {
    reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues])

  const form = useForm<StatusFormValues>({
    defaultValues,
  })
  const { reset, watch, setValue } = form

  const status = watch('status')?.id
  const isPaid = status === DebtStatusesEnum.PAID
  const canStatusChange = !isPaid && canDebtsUpdate

  const statusColor = useMemo(() => {
    if (status === DebtStatusesEnum.PAID) return 'green'
    if (status === DebtStatusesEnum.PROCESSING) return 'yellow'

    return 'gray'
  }, [status])

  const handleStatusChange = async (status: StatusFormValues['status']) => {
    try {
      setValue('status', status)
      await debtViewModel.statusChangeFx(status.id)
    } catch (e) {
      reset(defaultValues)
    }
  }

  const filteredStatus = useMemo(
    () =>
      isPaid
        ? debtStatusEnumOptions
        : debtStatusEnumOptions.filter(
            ({ id }) => id !== DebtStatusesEnum.PAID,
          ),
    [isPaid],
  )

  return (
    <FormProvider form={form}>
      <AutocompleteInput
        name='status'
        options={filteredStatus}
        startDecorator={null}
        disableClearable
        skeletonShow={isDebtLoading}
        readOnly={!canStatusChange || isChanged}
        onChange={(_, value) =>
          handleStatusChange(value as StatusFormValues['status'])
        }
        loading={isChanged}
        size='sm'
        sx={{
          width: '170px',
          marginBottom: '8px',
          border: 'none',
          '&': {
            ...STATUS_VARIANT[statusColor],
          },

          '&.Mui-focused:before': {
            boxShadow: 'none',
          },
        }}
      />
    </FormProvider>
  )
}
