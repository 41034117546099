import { useCallback } from 'react'
import { DebtViolation } from '~/shared/api/debtViolations'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { GridRow } from './types'

type ViolationDetailingProps = {
  debtId: UniqueId
}

export const ViolationDetailingList = ({ debtId }: ViolationDetailingProps) => {
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = DebtViolation.limit(pageSize)
        .where('debtId', debtId)
        .orderBy('-createdAt')

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((violation: DebtViolation) => ({
        ...violation.getAttributes(),
        id: violation?.getApiId() as UniqueId,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [debtId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Детализация'
      gridKey='violation-detailing-list'
      fetchRows={fetchRows}
      columns={columns}
      memoryLocal
    />
  )
}
