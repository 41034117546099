import { userOptionsStore } from '~/shared/api'

export const usePermissions = () => {
  const permissions = userOptionsStore.permissions || []

  const checkPermissions = (permission: string) =>
    permissions.includes(permission)

  const canUsersUpdate = checkPermissions('update users')
  const canUsersView = checkPermissions('view users')

  const canRolesUpdate = checkPermissions('update roles')
  const canRolesView = checkPermissions('view roles')

  const canDebtsUpdate = checkPermissions('update debts')
  const canDebtsView = checkPermissions('view debts')

  const canAuditsView = checkPermissions('view audits')

  const canSettingsView = canUsersView || canRolesView

  return {
    canUsersUpdate,
    canUsersView,
    canRolesUpdate,
    canRolesView,
    canAuditsView,
    canSettingsView,
    canDebtsUpdate,
    canDebtsView,
  }
}
