import { zodResolver } from '@hookform/resolvers/zod'
import { Grid } from '@mui/joy'
import { useStore } from 'effector-react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Element } from 'react-scroll'
import { Car, Driver } from '~/shared/api'
import { debtTypeEnumOptions } from '~/shared/config/constants'
import { TMS_DOMAIN } from '~/shared/config/env'
import { LinkDecorator } from '~/shared/ui/Buttons'
import {
  AsyncAutocompleteInput,
  AutocompleteInput,
  DatePickerInput,
  FormButtons,
  formButtonsModel,
  FormProvider,
  FormTitle,
  NumericInput,
  TextareaInput,
  TextInput,
} from '~/shared/ui/Form'
import { Container } from '~/shared/ui/Layout'
import { formSchema, FormValues } from './model'

type FormProps = {
  isCreating?: boolean
  canEdit?: boolean
  isSubmitting?: boolean
  isLoading?: boolean
  onSuccess?: (formValues: FormValues) => void
  defaultValues?: Partial<FormValues>
  canCarEdit?: boolean
}

export function Form({
  isCreating,
  canEdit,
  isSubmitting,
  isLoading,
  defaultValues,
  onSuccess,
  canCarEdit,
}: FormProps) {
  const isEditing = useStore(formButtonsModel.$isEditing)
  const disabled = !isCreating || isSubmitting
  const isCarDisabled = canCarEdit ? !isEditing : !isCreating

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  useEffect(() => {
    if (defaultValues) form.reset(defaultValues)
    // eslint-disable-next-line
  }, [defaultValues, isEditing])

  return (
    <FormProvider form={form} onSuccess={onSuccess} canEdit={canEdit}>
      <Element name='main'>
        <Container>
          <FormTitle>Основная информация</FormTitle>
          <Grid container spacing={2.5}>
            <Grid xs={6}>
              <AsyncAutocompleteInput
                label='Водитель'
                name='driverOption'
                fetchOptions={Driver.fetchOptions}
                readOnly={disabled}
                placeholder='Выбрать'
                skeletonShow={isLoading}
                endDecorator={
                  disabled &&
                  defaultValues?.driverExtId && (
                    <LinkDecorator
                      tooltipText='Перейти в карточку водителя'
                      href={`${TMS_DOMAIN}drivers/${defaultValues?.driverExtId}`}
                    />
                  )
                }
              />
            </Grid>
            <Grid xs={6}>
              <AutocompleteInput
                label='Тип'
                name='sourceType'
                options={debtTypeEnumOptions}
                readOnly={disabled}
                placeholder='Выбрать'
                skeletonShow={isLoading}
              />
            </Grid>
            <Grid xs={6}>
              <AsyncAutocompleteInput
                label='Автомобиль'
                name='carOption'
                fetchOptions={Car.fetchOptions}
                placeholder='Выбрать'
                skeletonShow={isLoading}
                readOnly={isCarDisabled || isSubmitting}
                endDecorator={
                  isCarDisabled &&
                  defaultValues?.driverExtId && (
                    <LinkDecorator
                      tooltipText='Перейти в карточку автомобиля'
                      href={`${TMS_DOMAIN}cars/${defaultValues?.carExtId}`}
                    />
                  )
                }
              />
            </Grid>
            {!isCreating && (
              <>
                <Grid xs={6}>
                  <TextInput
                    label='Основание'
                    name='sourceTitle'
                    readOnly
                    skeletonShow={isLoading}
                    endDecorator={
                      defaultValues?.sourceUrl && (
                        <LinkDecorator
                          tooltipText='Перейти'
                          href={defaultValues?.sourceUrl}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid xs={6}>
                  <DatePickerInput
                    label='Дата начала списания'
                    name='startAt'
                    readOnly
                    skeletonShow={isLoading}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Container>
      </Element>

      <Element name='payment'>
        <Container>
          <FormTitle>Оплата</FormTitle>
          <Grid container spacing={2.5} alignItems='flex-start'>
            <Grid xs={6} container spacing={2.5}>
              <Grid xs={6}>
                <NumericInput
                  label='Сумма'
                  name='total'
                  placeholder='Введите сумму'
                  skeletonShow={isLoading}
                  readOnly={(!isCreating && !isEditing) || isSubmitting}
                />
              </Grid>
              <Grid xs={6}>
                <NumericInput
                  label='Шаг'
                  name='paymentCost'
                  placeholder='Введите сумму'
                  skeletonShow={isLoading}
                  readOnly={(!isCreating && !isEditing) || isSubmitting}
                />
              </Grid>
              <Grid xs={6}>
                <NumericInput
                  label='Списано'
                  name='paid'
                  readOnly
                  skeletonShow={isLoading}
                />
              </Grid>
              <Grid xs={6}>
                <NumericInput
                  label='Осталось списать'
                  name='leftAmount'
                  readOnly
                  skeletonShow={isLoading}
                />
              </Grid>
            </Grid>
            <Grid xs={6}>
              <TextareaInput
                label='Комментарий'
                name='comment'
                placeholder='Введите комметарий'
                minRows={4}
                maxRows={4}
                skeletonShow={isLoading}
                readOnly={(!isCreating && !isEditing) || isSubmitting}
                sx={{
                  height: '138px',
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </Element>

      <FormButtons loading={isSubmitting} isCreating={isCreating} />
    </FormProvider>
  )
}
