import { usePermissions } from '~/entities/viewer'
import { canShowObject } from '~/shared/lib/utils'

type Header = { label: string; to: string; parent?: string }

export const useHeader = (): Header[] => {
  const { canDebtsView, canSettingsView } = usePermissions()

  return [
    ...canShowObject(canDebtsView, {
      label: 'Задолженности',
      to: '/debts',
    }),
    ...canShowObject(canSettingsView, {
      label: 'Настройки',
      to: '/settings/employees',
      parent: '/settings',
    }),
  ]
}
