import { ListDebts } from 'src/features/Debt/ListDebts'
import { Container, PageContainer } from '~/shared/ui/Layout'

export function ListDebtsPage() {
  return (
    <PageContainer title='Задолженности'>
      <Container>
        <ListDebts />
      </Container>
    </PageContainer>
  )
}
