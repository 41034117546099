import { useStore } from 'effector-react'
import { useMemo } from 'react'
import { Form } from '~/entities/Debt'
import { createFx, formSubmitted } from './model'

export const AddDebt = () => {
  const isSubmitting = useStore(createFx.pending)

  const defaultValues = useMemo(() => ({ paid: 0, paymentCost: 500 }), [])

  return (
    <Form
      isCreating
      onSuccess={formSubmitted}
      defaultValues={defaultValues}
      isSubmitting={isSubmitting}
    />
  )
}
