import * as z from 'zod'

import { dateSchema } from '~/shared/lib/schemas'
import { ApiModel } from './core'

const attributesSchema = z.object({
  vin: z.string(),
  plateNumber: z.string(),
  extId: z.string(),
})

const schema = z
  .object({
    createdAt: dateSchema.optional(),
    updatedAt: dateSchema.optional(),
  })
  .merge(attributesSchema)

export type CarAttributes = z.infer<typeof attributesSchema>

export class Car extends ApiModel<typeof schema, CarAttributes> {
  static jsonApiType = 'cars'

  static schema = schema

  getPlateNumber(): string {
    return this.getAttribute('plateNumber')
  }

  getExtId(): UniqueId {
    return this.getAttribute('extId')
  }

  getOption(): { id: UniqueId; label: string } {
    return {
      id: this.getApiId() as UniqueId,
      label: this.getPlateNumber(),
    }
  }

  static async fetchOptions(search: string) {
    const response = await Car.where('plateNumber', search ?? '').get(1)
    return response.getData().map((o) => o.getOption())
  }
}
