import { useCallback } from 'react'
import { CashDeposit } from '~/shared/api/cashDeposit'
import { AsyncGrid, FetchRowsFn } from '~/shared/ui/AsyncGrid'
import { columns } from './columns'
import { GridRow } from './types'

type CashListProps = {
  debtId: UniqueId
  gridKey: string
}

export const CashList = (props: CashListProps) => {
  const { debtId, gridKey } = props
  const fetchRows = useCallback<FetchRowsFn<GridRow>>(
    async (page, pageSize, _, sort) => {
      let builder = CashDeposit.limit(pageSize)
        .where('debtId', debtId)
        .with('user')
        .orderBy('-createdAt')

      sort
        ? (builder = builder.orderBy(sort))
        : (builder = builder.orderBy('-createdAt'))

      const response = await builder.get(page)
      const data = response.getData()
      const rows = data.map((cashDeposit: CashDeposit) => ({
        ...cashDeposit.getAttributes(),
        user: cashDeposit.getUser().getTitle(),
        id: cashDeposit?.getApiId() as UniqueId,
      }))

      const httpClientResponse = response.getHttpClientResponse()
      const axiosResponse = httpClientResponse?.getUnderlying()
      const total = axiosResponse?.data?.meta?.page?.total || 0

      return {
        rows,
        total,
      }
    },
    [debtId],
  )

  return (
    <AsyncGrid<GridRow>
      smallTitle='Внесение денежных средств'
      gridKey={gridKey}
      fetchRows={fetchRows}
      columns={columns}
      memoryLocal
    />
  )
}
