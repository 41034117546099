import { AddDebt } from '~/features/Debt/AddDebt'
import { Breadcrumbs, BreadcrumbsList } from '~/shared/ui/Breadcrumbs'
import { PageContainer, PageTitle, Container } from '~/shared/ui/Layout'

const breadcrumbs: BreadcrumbsList = [
  { title: 'Задолженности', link: '/debts' },
  { title: 'Новая задолженность' },
]

export function AddDebtPage() {
  return (
    <PageContainer title='Новая задолженность'>
      <Container isHeader>
        <Breadcrumbs list={breadcrumbs} />
        <PageTitle>Новая задолженность</PageTitle>
      </Container>

      <AddDebt />
    </PageContainer>
  )
}
