import { GridColDef } from '@mui/x-data-grid'
import { formatDateForUI, formatDateTimeForUI } from '~/shared/lib/date'
import { ColumnValue } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата создания',
    field: 'createdAt',
    flex: 1,
    renderCell: ({ value }) => (value ? formatDateTimeForUI(value) : ''),
  },
  {
    headerName: 'Дата внесения',
    field: 'depositingDate',
    flex: 1,
    renderCell: ({ value }) => (value ? formatDateForUI(value) : ''),
  },
  {
    headerName: 'Пользователь',
    field: 'user',
    sortable: false,
    flex: 1,
  },
  {
    headerName: 'Сумма',
    field: 'amount',
    flex: 1,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
  {
    headerName: 'Комментарий',
    field: 'comment',
    flex: 1,
  },
]
