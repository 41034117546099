import { GridColDef } from '@mui/x-data-grid'
import { ColumnValue } from '~/shared/ui/AsyncGrid'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Деталь и степень повреждения',
    field: 'description',
    flex: 1,
  },
  {
    headerName: 'Стоимость',
    field: 'cost',
    flex: 1,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
]
