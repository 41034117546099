import { GridColDef } from '@mui/x-data-grid'
import {
  debtStatusEnumLabels,
  debtTypeEnumLabels,
} from '~/shared/config/constants'
import { DebtStatusesEnum } from '~/shared/config/enums'
import { TMS_DOMAIN } from '~/shared/config/env'
import { ColumnDate, ColumnLink, ColumnValue } from '~/shared/ui/AsyncGrid'
import { ChipStatus } from '~/shared/ui/Chips'
import { GridRow } from './types'

export const columns: GridColDef<GridRow>[] = [
  {
    headerName: 'Дата и время',
    field: 'startAt',
    minWidth: 190,
    renderCell: ({ row, value }) => (
      <ColumnLink to={row.id}>
        <ColumnDate type='moscowDateTime' value={value} />
      </ColumnLink>
    ),
  },
  {
    headerName: 'Статус',
    field: 'status',
    minWidth: 192,
    renderCell: ({ row }) => {
      const variant =
        row.status === DebtStatusesEnum.SUSPENDED
          ? 'gray'
          : row.status === DebtStatusesEnum.PAID
          ? 'green'
          : row.status === DebtStatusesEnum.PROCESSING
          ? 'yellow'
          : null

      if (!variant) return null

      return (
        <ChipStatus
          label={debtStatusEnumLabels[row.status]}
          variant={variant}
        />
      )
    },
  },
  {
    headerName: 'Тип',
    field: 'sourceType',
    minWidth: 192,
    renderCell: ({ row }) => (
      <ColumnValue>{debtTypeEnumLabels[row.sourceType]}</ColumnValue>
    ),
  },
  {
    headerName: 'Сумма',
    field: 'total',
    minWidth: 180,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
  {
    headerName: 'Шаг',
    field: 'paymentCost',
    minWidth: 180,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
  {
    headerName: 'Списано',
    field: 'paid',
    minWidth: 180,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
  {
    headerName: 'Остаток',
    field: 'balance',
    minWidth: 180,
    renderCell: ({ value }) => (
      <ColumnValue>{value.toLocaleString()}</ColumnValue>
    ),
  },
  {
    headerName: 'Основание',
    field: 'source',
    minWidth: 192,
    sortable: false,
    renderCell: ({ value }) => {
      return value.url ? (
        <ColumnLink href={value.url} target='_blank'>
          {value.title}
        </ColumnLink>
      ) : (
        <ColumnValue>{value.title}</ColumnValue>
      )
    },
  },
  {
    headerName: 'Водитель',
    field: 'driver',
    minWidth: 350,
    sortable: false,
    renderCell: ({ row, value }) =>
      value && (
        <ColumnLink
          href={`${TMS_DOMAIN}drivers/${row.driverExtId}`}
          target='_blank'
        >
          {value}
        </ColumnLink>
      ),
  },
  {
    headerName: 'Автомобиль',
    field: 'car',
    minWidth: 189,
    sortable: false,
    renderCell: ({ row, value }) =>
      value && (
        <ColumnLink href={`${TMS_DOMAIN}cars/${row.carExtId}`} target='_blank'>
          {value}
        </ColumnLink>
      ),
  },
]
